import * as _ from 'lodash'
/**
 * Parse any updated operation hours into a string that contains the day, new and old
 * hours of operation.
 * @param {object} originalHours Original hours from yext
 * @param {object} modifiedHours Modified hours from user form edit
 */
export const parseHoursToString = (originalHours, modifiedHours) => {
  let hoursString = ''
  for (const [key, value] of Object.entries(originalHours)) {
    if (key in modifiedHours) {
      // day is now closed

      if (
        value.isClosed === false &&
        (modifiedHours[key]['isClosed'] ||
          modifiedHours[key]['isClosed'] === 'true')
      ) {
        const { start, end } = value['openIntervals'][0]

        hoursString += ` ^^ day=${key}, startOld=${start}, endOld=${end}, isClosedOld=false, startNew=00:00, endNew=00:00, isClosedNew=true `
      }

      // closed day is now open
      else if (
        value.isClosed === true &&
        !modifiedHours[key].isClosed &&
        modifiedHours[key].openIntervals[0].start &&
        modifiedHours[key].openIntervals[0].end
      ) {
        const { start: modStart, end: modEnd } = modifiedHours[key][
          'openIntervals'
        ][0]
        hoursString += ` ^^ day=${key}, startOld=00:00, endOld=00:00, isClosedOld=true, startNew=${modStart}, endNew=${modEnd}, isClosedNew=false `
      }

      // day was open but hours are modified
      else if (value['openIntervals']) {
        const { start: modStart, end: modEnd } = modifiedHours[key][
          'openIntervals'
        ][0]
        const { start, end } = value['openIntervals'][0]

        // if start time or end time was modified, only include the day for which a change was made
        if (start !== modStart || end !== modEnd) {
          hoursString += ` ^^ day=${key}, startOld=${start}, endOld=${end}, startNew=${modStart}, endNew=${modEnd}`
        }
      }
    }
  }
  return hoursString
}

/**
 * Parse Original hours of operation to a string.
 * @param {object} originalHours original hours object
 */
export const parseOriginalHoursToString = (originalHours) => {
  let hoursString = ''
  for (const [key, value] of Object.entries(originalHours)) {
    // check days for which a start and end time was specified
    if (value['openIntervals'] && !value['isClosed']) {
      const { start, end } = value['openIntervals'][0]
      hoursString += ` ^^ orgDay=${key}, isClosed=false, orgStart=${start}, orgEnd=${end}`
      // if a day which previously had isClosed flag now has hours, include the new data
    } else if (value['openIntervals'] && value['isClosed']) {
      hoursString += ` ^^ orgDay=${key}, isClosed=true, orgStart=00:00, orgEnd=00:00`
    }
  }
  return hoursString
}

export const hideFooterButtonStyles = () => (
  <style>{`
    div[class^='FormFooter-sc'] div button:disabled {
      display: none;
    }
    div[class^='FormFooter-sc'] {
      display: none;
    }
  `}</style>
)

export const showFooterButtonStyles = () => (
  <style jsx>{`
    div[class^='FormFooter-sc'] div button:disabled {
      display: block;
    }
    div[class^='FormFooter-sc'] {
      display: flex;
    }
  `}</style>
)

export const isColorLightOrDark = function(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color?.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color?.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "Light";
  } else {
    return "Dark";
  }
};

export const updateHours = (hrs) => {
  const hours = hrs
  if (hours && hours.monday && !('isClosed' in hours.monday)) {
    hours.monday.isClosed = false
  } else {
    if (hours && hours.monday && !hours.monday.openIntervals) {
      hours.monday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.tuesday && !('isClosed' in hours.tuesday)) {
    hours.tuesday.isClosed = false
  } else {
    if (hours && hours.tuesday && !hours.tuesday.openIntervals) {
      hours.tuesday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.wednesday && !('isClosed' in hours.wednesday)) {
    hours.wednesday.isClosed = false
  } else {
    if (hours && hours.wednesday && !hours.wednesday.openIntervals) {
      hours.wednesday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.thursday && !('isClosed' in hours.thursday)) {
    hours.thursday.isClosed = false
  } else {
    if (hours && hours.thursday && !hours.thursday.openIntervals) {
      hours.thursday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.friday && !('isClosed' in hours.friday)) {
    hours.friday.isClosed = false
  } else {
    if (hours && hours.friday && !hours.friday.openIntervals) {
      hours.friday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.saturday && !('isClosed' in hours.saturday)) {
    hours.saturday.isClosed = false
  } else {
    if (hours && hours.saturday && !hours.saturday.openIntervals) {
      hours.saturday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.sunday && !('isClosed' in hours.sunday)) {
    hours.sunday.isClosed = false
  } else {
    if (hours && hours.sunday && !hours.sunday.openIntervals) {
      hours.sunday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  return hours
}

export const parseStaffPages = (staffPageData) => {
  return {
    ...staffPageData?.fields,
    __typename: 'TemplateDynamicLayoutPage',
    itemsCollection: {
      items: staffPageData?.fields?.items.map((item) => {
        if (item.sys.contentType.sys.id == 'secondaryHero') {
          return {
            ...item.fields,
            __typename: 'SecondaryHero',
            backgroundImage: {
              ...item.fields.backgroundImage?.fields,
              __typename: 'Image',
              image: {
                ...item.fields.backgroundImage?.fields?.image?.fields,
                __typename: 'Asset',
                url:
                  item.fields.backgroundImage?.fields?.image?.fields.file.url,
                size:
                  item.fields.backgroundImage?.fields?.image?.fields.file
                    .details.size,
                width:
                  item.fields.backgroundImage?.fields?.image?.fields.file
                    .details.image.width,
                height:
                  item.fields.backgroundImage?.fields?.image?.fields.file
                    .details.image.height,
                contentType:
                  item.fields.backgroundImage?.fields?.image?.fields.file
                    .contentType,
                file: {
                  url:
                    item.fields.backgroundImage?.fields?.image?.fields.file.url,
                  size:
                    item.fields.backgroundImage?.fields?.image?.fields.file
                      .details.size,
                  width:
                    item.fields.backgroundImage?.fields?.image?.fields.file
                      .details.image.width,
                  height:
                    item.fields.backgroundImage?.fields?.image?.fields.file
                      .details.image.height,
                  contentType:
                    item.fields.backgroundImage?.fields?.image?.fields.file
                      .contentType,
                },
              },
            },
          }
        } else if (item.sys.contentType.sys.id == 'staffBlade') {
          return {
            ...item.fields,
            __typename: 'StaffBlade',
            sys: { ...item.sys },
            bodyText: { json: item.fields.bodyText },
            staffCardCollection: {
              __typename: 'StaffBladeStaffCardCollection',
              items: item.fields.staffCard.map((card) => ({
                ...card.fields,
                __typename: 'Staff',
                sys: { ...card.sys },
                staffImage: {
                  ...card.fields?.staffImage?.fields,
                  __typename: 'Image',
                  image: {
                    url: card.fields?.staffImage?.fields.image.fields.file.url,
                    size:
                      card.fields?.staffImage?.fields.image.fields.file.details
                        .size,
                    width:
                      card.fields?.staffImage?.fields.image.fields.file.details
                        .image.width,
                    height:
                      card.fields?.staffImage?.fields.image.fields.file.details
                        .image.height,
                    contentType:
                      card.fields?.staffImage?.fields.image.fields.file
                        .contentType,
                  },
                },
              })),
            },
          }
        }
      }),
    },
  }
}

function organizeGlobalStaffData(globalStaffPage, staffPageData){
  let pageData = JSON.parse(JSON.stringify(globalStaffPage))
  let sysStaffPageData = staffPageData?.map((staff) => ({
      ...staff,
      sysId: 'staffBlade'
    }))

  let count = 0;
  pageData?.fields?.items.forEach((content, index) => {
    if (content?.sys?.contentType?.sys?.id === 'staffBlade' || content?.sysId === 'staffBlade') {
      pageData.fields.items[index] = sysStaffPageData[count]
      count +=1;
    }
  });

  return pageData;
}

export const parseStaffPagesFromNewStaffData = (
  staffPageData,
  banner,
  globalStaffPage
) => {
  //Parse each possible content type and add them to the page to build the preview frame
  let pageData = organizeGlobalStaffData(globalStaffPage, staffPageData)
  let parsedStaffPage = []
    pageData?.fields?.items?.map((content) => {
    //SECONDARY HERO
    if(content?.sys?.contentType?.sys?.id == 'secondaryHero'){
      let bannerImageFile = {
        url: content?.fields?.backgroundImage?.fields?.image?.fields?.file?.url,
        size: content?.fields?.backgroundImage?.fields?.image?.fields?.file?.details?.size,
        width: content?.fields?.backgroundImage?.fields?.image?.fields?.file?.details?.image?.width,
        height: content?.fields?.backgroundImage?.fields?.image?.fields?.file?.details?.image?.height,
        contentType: content?.fields?.backgroundImage?.fields?.image?.fields?.file?.contentType,
      }
      let parsedBanner = {
        ...content?.fields,
        __typename: 'SecondaryHero',
        backgroundImage: {
          ...content?.fields?.backgroundImage?.fields,
          __typename: 'Image',
          image: {
            ...content?.fields?.backgroundImage?.fields?.image?.fields,
            __typename: 'Asset',
            ...bannerImageFile,
            file: {
              ...bannerImageFile,
            },
          },
        },
      }
      parsedStaffPage.push(parsedBanner)
    }
    //PROMO BANNER
    if(content?.sys?.contentType?.sys?.id == 'promoBanner'){
      let promoBannerImageFile = content?.fields?.overrideBackground ? {
        url: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.url,
        size: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.size,
        width: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.image.width,
        height: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.image.height,
        contentType: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.contentType,
      } : {
        url: content?.fields?.backgroundImage?.fields?.image?.fields.file.url,
        size: content?.fields?.backgroundImage?.fields?.image?.fields.file.details.size,
        width: content?.fields?.backgroundImage?.fields?.image?.fields.file.details.image.width,
        height: content?.fields?.backgroundImage?.fields?.image?.fields.file.details.image.height,
        contentType: content?.fields?.backgroundImage?.fields?.image?.fields.file.contentType,
      }
      let parsedPromoBanner = {
        ...content?.fields,
        __typename: 'PromoBanner',
        fontColor: content?.fields?.fontColor ? content.fields.fontColor :
        (content?.fields?.overrideBackground?.fields?.colorBackground ? (isColorLightOrDark() === 'Light' ? 'Dark': 'Light') : ''),
        textBlock: { json: content?.fields?.textBlock},
        multipleCtaCollection: content?.fields?.MultipleCta ? {
          items: content?.fields?.MultipleCta?.map((cta) => ({
            ...cta,
            label: cta.fields.label,
            sys: { ...cta.sys }
          }))
        } : '',
        backgroundImage: {
          ...content?.fields?.backgroundImage?.fields,
          __typename: 'Image',
          image: {
            ...content?.fields?.backgroundImage?.fields?.image?.fields,
            __typename: 'Asset',
            ...promoBannerImageFile,
            file: {
              ...promoBannerImageFile,
            },
          },
        },
        overrideBackground: {
          ...content?.fields?.overrideBackground?.fields
        }
      }
      parsedStaffPage.push(parsedPromoBanner)
    }
    //60/40 PANEL
    if(content?.sys?.contentType?.sys?.id == 'panel'){
      let panel60_40ImageFile = content?.fields?.overrideBackground ? {
        url: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.url,
        size: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.size,
        width: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.image.width,
        height: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.image.height,
        contentType: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.contentType,
      } : {
        url: content?.fields?.bladeBackgroundImage?.fields?.image?.fields.file.url,
        size: content?.fields?.bladeBackgroundImage?.fields?.image?.fields.file.details.size,
        width: content?.fields?.bladeBackgroundImage?.fields?.image?.fields.file.details.image.width,
        height: content?.fields?.bladeBackgroundImage?.fields?.image?.fields.file.details.image.height,
        contentType: content?.fields?.bladeBackgroundImage?.fields?.image?.fields.file.contentType,
      }
      let parsedPanel60_40 = {
        ...content?.fields,
        __typename: 'Panel',
        shortText: { json: content?.fields?.shortText},
        bladeBackgroundImage: {
          ...content?.fields?.bladeBackgroundImage?.fields,
          __typename: 'Image',
          image: {
            ...content?.fields?.bladeBackgroundImage?.fields?.image?.fields,
            __typename: 'Asset',
            ...panel60_40ImageFile,
            file: {
              ...panel60_40ImageFile,
            },
          },
        },
        overrideBackground: {
          ...content?.fields?.overrideBackground?.fields
        }
      }
      parsedStaffPage.push(parsedPanel60_40)
    }
    //PENCIL BANNER
    if(content?.sys?.contentType?.sys?.id == 'pencilBanner'){
      let pencilBannerImageFile = content?.fields?.overrideBackground?.imageBackground ? {
        url: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.url,
        size: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.size,
        width: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.image.width,
        height: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.details.image.height,
        contentType: content?.fields?.overrideBackground?.fields?.imageBackground?.fields.file.contentType,
      } : ''
      let parsedPencilBanner = {
        ...content?.fields,
        __typename: 'PencilBanner',
        fontColor: content?.fields?.fontColor ? content.fields.fontColor :
        (content?.fields?.overrideBackground?.fields?.colorBackground ? (isColorLightOrDark() === 'Light' ? 'Dark': 'Light') : ''),
        message: { json: content?.fields?.message},
        overrideBackground: {
          ...content?.fields?.overrideBackground?.fields,
          imageBackground: {
            ...content?.fields?.bladeBackgroundImage?.fields,
          __typename: 'Image',
          image: {
            ...content?.fields?.bladeBackgroundImage?.fields?.image?.fields,
            __typename: 'Asset',
            ...pencilBannerImageFile,
            file: {
              ...pencilBannerImageFile,
            },
          },
          }
        }
      }
      parsedStaffPage.push(parsedPencilBanner)
    }
    //RICH TEXT BLOB
    if(content?.sys?.contentType?.sys?.id == 'richTextBlob'){
      let parsedRichBlobText = {
        ...content?.fields,
        __typename: 'RichTextBlob',
        body: {json: content?.fields?.body},
        topPadding: content?.fields?.topPadding,
        bottomPadding: content?.fields?.bottomPadding,
        categoryTitle: content?.fields?.categoryTitle,
        anchorLink: content?.fields?.anchorLink
      }
      parsedStaffPage.push(parsedRichBlobText)
    }
    //ACCORDION BLADE
    if(content?.sys?.contentType?.sys?.id == 'accordionBlade'){
      let parsedAccordionBlade = {
        ...content?.fields,
        __typename: 'AccordionBlade',
        anchorLink: content?.fields?.anchorLink,
        accordionType: content?.fields?.accordionType,
        accordionContentCollection: content?.fields?.accordionContent ? {
          items: content?.fields?.accordionContent?.map((accordion) => ({
            ...accordion.fields,
            header: accordion?.fields?.header,
            body: {json: accordion?.fields?.body},
            sys: { ...accordion?.sys }
          }))
        } : '',
        description: {json: content?.fields?.description},
        fontColor: content?.fields?.fontColor ? content.fields.fontColor :
        (content?.fields?.overrideBackground?.fields?.colorBackground ? (isColorLightOrDark() === 'Light' ? 'Dark': 'Light') : ''),
        sectionHeadline: content?.fields?.sectionHeadline
      }
      parsedStaffPage.push(parsedAccordionBlade)
    }
    //SPACER
    if(content?.sys?.contentType?.sys?.id == 'spacer'){
      let parsedSpacer = {
        ...content?.fields,
        __typename: 'Spacer',
        space: content?.fields?.space,
        background: {
          ...content?.fields?.background?.fields
        }
      }
      parsedStaffPage.push(parsedSpacer)
    }
    //STAFF BLADE CARDS
    if(content?.sys?.contentType?.sys?.id == 'staffBlade' || content?.sysId == 'staffBlade'){
      let parsedStaffBlade = {
        ...content,
        name: content?.groupName ? content.groupName : ' ',
        sectionHeadline: content.groupName,
        __typename: 'StaffBlade',
        sys: { id: content.key },
        bodyText: { ...content.bodyText },
        staffCardCollection: {
          __typename: 'StaffBladeStaffCardCollection',
          items: content?.staffMembers?.map((card) => ({
            ...card,
            longBio: card.longBio,
            shortBio: card.shortBio,
            staffName: card.name,
            __typename: 'Staff',
            sys: { id: card.staffEntryId },
            staffImage: {
              __typename: 'Image',
              image: {
                url: card.staffImage,
                contentType: 'image/jpeg',
              },
            },
          })),
        },
      }
      parsedStaffPage.push(parsedStaffBlade)
    }
  })

  return {
    ...staffPageData,
    __typename: 'TemplateDynamicLayoutPage',
    metaDescription: globalStaffPage.fields.metaDescription,
    pageType: globalStaffPage.fields.pageType,
    title: globalStaffPage.fields.title,
    titleTag: globalStaffPage.fields.titleTag,
    url: globalStaffPage.fields.url,
    itemsCollection: {
      items: [
        ...parsedStaffPage?.map((item) => ({
          ...item,
        })),
      ],
    },
  }
}
