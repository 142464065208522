import React, { useContext, useState, useEffect, useRef } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { addOrUpdateHOONewValue, getLatestHoursUpdates } from '../../api/publishLogs'
import './WebsitePageMain.css'
import './WebsitePageHoursEditForm.css'
import './WebsiteEmergencyBannerEditForm.css'
import { Context } from '../../../Store'
import Showdown from 'showdown'
import TurndownService from 'turndown'
import { hoursMessages } from '../../constants/messages/en'
import ForwardArrow from '-!svg-react-loader?name=Icon!../../static/images/next.svg'

import {
  useGlobalState,
  setIsHoursUpdated,
  setUpdatedHours,
  setIsHoursDeleted,
} from '../../hooks/state/state'
import { updateHours } from './helper'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import ReactGA from 'react-ga'
import Sidebar from 'src/components/Staff/Sidebar'
import CustomSwitch from 'src/components/CustomSwitch/CustomSwitch'
import { ParagraphSmall, SectionLink } from './WebsitePageHoursEditForm.styles'
import StandardHours from 'src/components/Hours/Standard/StandardHours'
import SpecialHours from 'src/components/Hours/Special/SpecialHours'
import MultiLocationSelection from 'src/components/MultilocationSelection/MultilocationSelection'

const WebsitePageHours = ({ routingInfo, user }) => {
  const history = useHistory()
  const [iframeView, setIframeView] = useState('large')
  const {setIsAlertActive} = useContext(Context)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isUpdated] = useGlobalState('isBannerUpdated')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [isHoursUpdated] = useGlobalState('isHoursUpdated')
  const [isEBDeleted] = useGlobalState('isEBDeleted')
  const [updatedHours] = useGlobalState('updatedHours')
  const [initialHours] = useGlobalState('initialHours')
  const [selectedSite] = useGlobalState('selectedSite')
  const [yextResponse] = useGlobalState('yextResponse')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [currentYextId] = useGlobalState('currentYextId')
  const [multiLocations] = useGlobalState('multiLocations')
  const [heroCarouselCards] = useGlobalState('homePageHeroCards')
  const [bannerText, setBannerText] = useState(
    isUpdated ? descriptionUpdated : descriptionInitial.text
  )

  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [showExtendedHours, setShowExtendedHours] = useState(
    initialHours.c_extendedHours &&
      Object.keys(initialHours.c_extendedHours).length > 0
  )
  const [step, setStep] = useState('multilocation')
  const [saving, setSaving] = useState(false)
  const [intialSpecialHours, setIntialSpecialHours] = useState([])
  const standardHours = useRef({})
  const standardHoursText = useRef({})
  const extendedHours = useRef({})
  const extendedHoursText = useRef({})
  const specialHours = useRef([])
  const errorRef = useRef()
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  useEffect(() => {
    setHours(initialHours, updatedHours)
  }, [initialHours, updatedHours])

  useEffect(() => {
    if (showErrorMessage) {
      // eslint-disable-next-line no-unused-expressions
      errorRef.current?.scrollIntoView()
    }
  }, [showErrorMessage])

  const setHours = (regular, updated) => {
    const hours =
      updated && Object.keys(updated).length > 0 && updated[currentYextId]
        ? { ...updated[currentYextId] }
        : { ...regular }

    standardHours.current = {
      monday: JSON.parse(JSON.stringify(hours.hours.monday)),
      tuesday: JSON.parse(JSON.stringify(hours.hours.tuesday)),
      wednesday: JSON.parse(JSON.stringify(hours.hours.wednesday)),
      thursday: JSON.parse(JSON.stringify(hours.hours.thursday)),
      friday: JSON.parse(JSON.stringify(hours.hours.friday)),
      saturday: JSON.parse(JSON.stringify(hours.hours.saturday)),
      sunday: JSON.parse(JSON.stringify(hours.hours.sunday)),
    }
    standardHoursText.current = `${hours.additionalHoursText || ''}`
    if (
      hours.c_extendedHours &&
      Object.keys(hours.c_extendedHours).length > 0
    ) {
      extendedHours.current = JSON.parse(JSON.stringify(hours.c_extendedHours))
    } else {
      if (step == 'extendedHours') setStep('main')
      extendedHours.current = null
    }

    setShowExtendedHours(
      hours.c_extendedHours && Object.keys(hours.c_extendedHours).length > 0
    )
    extendedHoursText.current = `${hours.c_extendedAdditionalHoursText || ''}`
    specialHours.current = hours.holidayHours
      ? JSON.parse(JSON.stringify(hours.holidayHours))
      : []
    setIntialSpecialHours(hours.holidayHours
      ? JSON.parse(JSON.stringify(hours.holidayHours))
      : [])

    updatePreview()
  }

  const goBack = () => {
    if (step !== 'main' && step !== 'multilocation') {
      setStep('main')
    } else {
      history.push(
        `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
      )
    }
  }

  const initDayValue = (isClosed) => {
    return {
      isClosed,
      openIntervals: [
        {
          start: '00:00',
          end: '23:59',
        },
      ],
    }
  }

  const initHours = (status) => {
    return {
      monday: initDayValue(status),
      tuesday: initDayValue(status),
      wednesday: initDayValue(status),
      thursday: initDayValue(status),
      friday: initDayValue(status),
      saturday: initDayValue(status),
      sunday: initDayValue(status),
    }
  }

  /* format description */
  let converter = new Showdown.Converter()
  const turndownService = new TurndownService()
  const truncateNode = (paraElem, limit) => {
    if (paraElem.nodeType === Node.TEXT_NODE) {
      paraElem.textContent = paraElem.textContent.substring(0, limit)
      return limit - paraElem.textContent.length
    }
    paraElem.childNodes.forEach((child) => {
      limit = truncateNode(child, limit)
    })
    return limit
  }
  const getTruncatedHTMLOrMarkdown = (text, limit, returnMarkdown = false) => {
    if (text && limit) {
      let formattedText = converter.makeHtml(text)
      let docNode = new DOMParser().parseFromString(formattedText, 'text/html')
      let paraElem = docNode.body.firstElementChild
      truncateNode(paraElem, limit)
      if (paraElem) {
        if (returnMarkdown) {
          let dummyDiv = document.createElement('div')
          dummyDiv.appendChild(paraElem)
          let markdownText = turndownService.turndown(dummyDiv.innerHTML)
          return markdownText
        }
        return paraElem
      }
    }
    return ''
  }

  let description = isUpdated ? descriptionUpdated : descriptionInitial.text
  let trimmedMarkdown = getTruncatedHTMLOrMarkdown(description, 500, true)

  let initialValues = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    description: trimmedMarkdown.replace(/\\/g, ''),
  }

  const initial = isHoursUpdated ? { ...updatedHours[currentYextId] } : { ...initialHours }

  const initialHoursValues = {
    mainHours: {
      hours: updateHours(initial.hours),
      additionalHoursText: initial.additionalHoursText,
    },
    extendedHours: {
      extendedHours: updateHours(initial.c_extendedHours),
      c_extendedAdditionalHoursText: initial.c_extendedAdditionalHoursText,
    },
    haveExtendedHours:
      typeof initial.c_extendedHours != 'undefined' && initial.c_extendedHours != null &&
      Object.keys(initial.c_extendedHours).length > 0,
  }

  window.onbeforeunload = function () {
    if (isFormDirty && !isEBDeleted) return ''
    else return undefined
  }

  const updatePreview = () => {
    const updatedHoursData = {
      mainHours: {
        hours: updateHours(standardHours.current),
        additionalHoursText: standardHoursText.current,
      },
      extendedHours: {
        extendedHours: updateHours(extendedHours.current),
        c_extendedAdditionalHoursText: extendedHoursText.current,
      },
      haveExtendedHours:
        extendedHours.current != null &&
        Object.keys(extendedHours.current).length > 0,
    }

    sendMsg(updatedHoursData)
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const msg = JSON.parse(JSON.stringify(jsonMsg))
    if (msg) {
      if (msg.content && msg.content.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(msg.content.description)
        let regex = /<\/?p[^>]*>/gi
        msg.content.description = formattedText.replace(regex, '')
        setBannerText(formattedText.replace(regex, ''))
      }
      const strMsg = JSON.stringify(msg)
      win.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsgOnToggle = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto'
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        description: bannerText,
      }
      sendMsg({ type: 'EmergencyBanner', content: bannerData })
      sendMsg(initialHoursValues)
    })
  }

  const sendOnLoadMsg = () => {
    // setTimeout(() => {
    let frame = document.getElementById('websitePreviewFrame')
    if (frame) {
      frame.style['pointer-events'] = 'auto'
      sendMsg({ type: 'EmergencyBanner', content: initialValues })
      sendMsg(initialHoursValues)
      sendMsg({
        type: 'HOME_CAROUSEL_CARDS_UPDATED',
        content: heroCarouselCards,
      })
      sendMsg({
        goToHoursOfOperation: true,
      });
    }
    // }, 500)
  }

  const deleteHolidayHours = async (item, doUpdateHours) => {
    const newOpenInterval = item.openIntervals.map((oi) => ({
      oldStart: oi.start,
      oldEnd: oi.end,
    }))

    const newLogObject = {
      type: 'Hours',
      hoo_fieldName: 'holiday',
      hoo_day: item.date,
      oldValue: item.isClosed ? 'Closed' : 'Open',
      newValue: '',
      openIntervals: newOpenInterval,
    }

    const input = {
      userId: routingInfo.user?.id,
      userEmail: routingInfo.account.userName,
      websiteId: selectedSite.siteId.toString(),
      yextId: currentYextId.toString(),
      websiteName: selectedSite.name,
      updateType: 'Hours',
      savedUpdates: [newLogObject],
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
    }

    if(doUpdateHours){
      const newHours = {
        hours: { ...standardHours.current },
        additionalHoursText: standardHoursText.current,
        c_extendedHours:
          extendedHours.current && showExtendedHours
            ? { ...extendedHours.current }
            : null,
        c_extendedAdditionalHoursText: showExtendedHours
          ? extendedHoursText.current
          : '',
        holidayHours: [...specialHours.current],
      }
      let currentUpdatedhours = JSON.parse(JSON.stringify(updatedHours))

      currentUpdatedhours[currentYextId]
        ? (currentUpdatedhours[currentYextId] = newHours)
        : (currentUpdatedhours = Object.assign(updatedHours, {
            [currentYextId]: newHours,
          }))


      currentUpdatedhours[currentYextId].holidayHours.splice(updatedHours[currentYextId].holidayHours.findIndex(each=>
        each.date==item.date)
      , 1)
      setUpdatedHours(currentUpdatedhours)
    }

    await addOrUpdateHOONewValueLogRest(input)
  }

  const addOrUpdateHOONewValueLogRest = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      await addOrUpdateHOONewValue(query)
    } catch (e) {
      console.error('Exc caught while addOrUpdateHOONewValueLogRest():', e)
    }
  }

  const resetChanges = async () => {
    setHours(initialHours, updatedHours)
    setIsFormDirty(false)
    setShowErrorMessage(false)
    setResetForm(true)
  }

  const isSpecialHoursValid = (data) => {
    if (!data) return true
    let result = true
    data.forEach((sh) => {
      if (sh.date == '') {
        result = false
        return
      }
      if (sh.isClosed) return
      sh.openIntervals.forEach((interval) => {
        if (interval.start == '' || interval.end == '') {
          result = false
          return
        } else {
          let startTime = new Date(`
        '01/01/2020' ${interval.start}
      `)
          let endTime = new Date(`01/01/2020 ${interval.end}`)
          startTime = startTime.getTime()
          endTime = endTime.getTime()
          if (startTime >= endTime) {
            result = false
            return
          }
        }
      })
    })
    return result
  }

  const saveChanges = async () => {
    setSaving(true)
    if (!isSpecialHoursValid([...specialHours.current])) {
      alert('Your changes were not saved. There is something wrong!')
      setSaving(false)
      return
    }
    const totalHours = extendedHours.current
      ? [
          ...Object.entries(standardHours.current),
          ...Object.entries(extendedHours.current),
        ]
      : [...Object.entries(standardHours.current)]

    let invalidSplitHours = false
    Object.entries(totalHours).forEach((day) => {
      let intervals = day[1]
      let startTime = new Date(`
        '01/01/2020' ${intervals[1].openIntervals[0].start}
      `)
      let endTime = new Date(`01/01/2020 ${intervals[1].openIntervals[0].end}`)
      startTime = startTime.getTime()
      endTime = endTime.getTime()

      if (intervals[1].openIntervals.length > 1) {
        let firstStartIntrvl = startTime
        let firstEndIntrvl = endTime

        let secondStartIntrvl = new Date(`
        '01/01/2020' ${intervals[1].openIntervals[1].start}`)
        let secondEndIntrvl = new Date(`
          '01/01/2020' ${intervals[1].openIntervals[1].end}`)
        secondStartIntrvl = secondStartIntrvl.getTime()
        secondEndIntrvl = secondEndIntrvl.getTime()

        if (
          firstStartIntrvl >= firstEndIntrvl ||
          firstEndIntrvl > secondStartIntrvl ||
          secondStartIntrvl >= secondEndIntrvl
        ) {
          invalidSplitHours = true
        }
      }
    })

    if (invalidSplitHours) {
      alert(
        'Invalid hours have been entered. Please verify your hours or contact Marketing Support to make changes.'
      )
      setSaving(false)
      return
    }

    let latestSave = null
    const result = await getLatestHoursUpdates(
      user.id,
      selectedSite.siteId,
      currentYextId
    )
    if (result?.data?.latestHoursUpdates?.length > 0) {
      latestSave = result.data.latestHoursUpdates
    }

    const prepHours = async (hours, extended = false) => {
      let hackyYextId = null
      if (currentYextId.toString().length < 4) {
        hackyYextId = `0${currentYextId}`
      }

      // get yext data
      const getYextData = async (yextId) => {
        return await fetch(
          `${process.env.EE_REST_YEXT_ENDPOINT}?yextID=${yextId}`,
          {
            headers: {
              'Ocp-Apim-Subscription-Key':
                process.env.OCP_APIM_SUBSCRIPTION_KEY,
            },
          }
        ).then((response) => {
          if (response.ok) return response.json()
        })
      }

      const yextData = await getYextData(
        hackyYextId != null ? hackyYextId : currentYextId
      )

      const savedYext = JSON.parse(JSON.stringify(yextData.response))

      const weekDays = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ]

      Object.entries(hours).forEach(async (day) => {
        const getOldIsClosed = () => {
          let isHoliday = false
          if (!weekDays.includes(day[0])) isHoliday = true
          const isHolidayClosed =
            savedYext.hours.holidayHours?.find((hh) => hh.date == day[0])
              ?.isClosed ?? false

          return extended
            ? savedYext.c_extendedHours
              ? savedYext.c_extendedHours[`${day[0]}`]?.isClosed
                ? true
                : false
              : false
            : isHoliday
            ? !!isHolidayClosed
            : savedYext.hours[`${day[0]}`]?.isClosed
            ? true
            : false
        }

        const oldIsClosed = getOldIsClosed()

        //Check if change goes back to initial state in the same session (oldIsClosed)
        if (extended && latestSave && oldIsClosed !== null && oldIsClosed === day[1].isClosed) {
          for (let i = 0; i < latestSave.length; i++) {
            if (latestSave[i].hoo_fieldName === 'status' && latestSave[i].hoo_day === day[0] && latestSave[i].type === 'Ext Hrs') {

              let newIsClosed = day[1].isClosed ?? false
              if (day[1].isClosed == 'split') {
                newIsClosed = false
              };

              if (oldIsClosed === newIsClosed) {
                if (!updateType.includes('Ext Hours')) {
                    updateType += 'Ext Hours, ';
                }
              };

              const newLogObject = {
              type: 'Ext Hrs',
              hoo_fieldName: 'status',
              hoo_day: day[0],
              oldValue: oldIsClosed ? 'Closed' : 'Open',
              newValue: newIsClosed ? 'Closed' : 'Open',
              };

              savedUpdates.push(newLogObject);
            }
          }
        } else if (!extended && latestSave && oldIsClosed !== null && oldIsClosed === day[1].isClosed) {
          for (let i = 0; i < latestSave.length; i++) {
          if (latestSave[i].hoo_fieldName === 'status' && latestSave[i].hoo_day === day[0] && latestSave[i].type === 'Hours') {

            let newIsClosed = day[1].isClosed ?? false
              if (day[1].isClosed == 'split') {
                newIsClosed = false
              };

              if (oldIsClosed === newIsClosed) {
                if (!updateType.includes('Hours')) {
                    updateType += 'Hours, ';
                }
              };

              const newLogObject = {
              type: 'Hours',
              hoo_fieldName: 'status',
              hoo_day: day[0],
              oldValue: oldIsClosed ? 'Closed' : 'Open',
              newValue: newIsClosed ? 'Closed' : 'Open',
              };

              savedUpdates.push(newLogObject);
          }
        }
        }

        let newIsClosed = day[1].isClosed ?? false
        if (day[1].isClosed == 'split') {
          newIsClosed = false
        }

        if (oldIsClosed !== newIsClosed) {
          if (!updateType.includes(extended ? 'Ext Hours' : 'Hours')) {
            updateType += extended ? 'Ext Hours, ' : 'Hours, '
          }

          const newLogObject = {
            type: extended ? 'Ext Hrs' : 'Hours',
            hoo_fieldName: 'status',
            hoo_day: day[0],
            oldValue: oldIsClosed ? 'Closed' : 'Open',
            newValue: newIsClosed ? 'Closed' : 'Open',
          }

          savedUpdates.push(newLogObject)
        }

        const getOldIntervals = () => {

          let isHoliday = false
          if (!weekDays.includes(day[0])) isHoliday = true
          const isHolidayOpenIntervals = savedYext.hours.holidayHours?.find(
            (hh) => hh.date == day[0]
          )?.openIntervals

          return extended
            ? savedYext.c_extendedHours
              ? savedYext.c_extendedHours[`${day[0]}`]?.openIntervals
              : undefined
            : isHoliday
            ? isHolidayOpenIntervals
            : savedYext.hours[`${day[0]}`]?.openIntervals
        }

        const oldIntervals = getOldIntervals()

        //Check if change goes back to initial state in the same session (oldIntervals)
        if (extended && latestSave && oldIntervals && JSON.stringify(oldIntervals) === JSON.stringify(day[1].openIntervals)) {
          for (let i = 0; i < latestSave.length; i++) {
            if (latestSave[i].hoo_fieldName === 'interval' && latestSave[i].hoo_day === day[0] && latestSave[i].type === 'Ext Hrs') {

              if (!updateType.includes('Ext Hours')) {
                  updateType += 'Ext Hours, ';
              }

              const openIntervals = [];
              day[1].openIntervals.forEach((interval, index) => {
                  openIntervals.push({
                      oldStart: oldIntervals && oldIntervals[index] ? oldIntervals[index].start : null,
                      oldEnd: oldIntervals && oldIntervals[index] ? oldIntervals[index].end : null,
                      newStart: interval.start,
                      newEnd: interval.end,
                  });
              });

              const newLogObject = {
                  type: 'Ext Hrs',
                  hoo_fieldName: 'interval',
                  hoo_day: day[0],
                  openIntervals: openIntervals,
              };

              savedUpdates.push(newLogObject);
            }
          }
        } else if (!extended && latestSave && oldIntervals && JSON.stringify(oldIntervals) === JSON.stringify(day[1].openIntervals)) {
          for (let i = 0; i < latestSave.length; i++) {
          if (latestSave[i].hoo_fieldName === 'interval' && latestSave[i].hoo_day === day[0] && latestSave[i].type === 'Hours') {

            if (!updateType.includes('Hours')) {
                updateType += 'Hours, ';
            }

            const openIntervals = [];
            day[1].openIntervals.forEach((interval, index) => {
                openIntervals.push({
                    oldStart: oldIntervals && oldIntervals[index] ? oldIntervals[index].start : null,
                    oldEnd: oldIntervals && oldIntervals[index] ? oldIntervals[index].end : null,
                    newStart: interval.start,
                    newEnd: interval.end,
                });
            });

            const newLogObject = {
                type: 'Hours',
                hoo_fieldName: 'interval',
                hoo_day: day[0],
                openIntervals: openIntervals,
            };

            savedUpdates.push(newLogObject);
          }
        }
        }

        // if oldintervals is undefined, it means old isClosed is true and most likely coming from yext
        // and if new isClosed is also true, it means we should not save changes for its intervals
        // so in order to save changes, if oldintervals is undefined, new isClosed should be false
        // OR oldintervals has value

        if (
          ((!oldIntervals && !newIsClosed) || oldIntervals) &&
          JSON.stringify(oldIntervals) !== JSON.stringify(day[1].openIntervals)
        ) {
          if (!updateType.includes(extended ? 'Ext Hours' : 'Hours')) {
            updateType += extended ? 'Ext Hours, ' : 'Hours, '
          }

          const openIntervals = []
          day[1].openIntervals.forEach((interval, index) => {
            openIntervals.push({
              oldStart:
                oldIntervals && oldIntervals[index]
                  ? oldIntervals[index].start
                  : null,
              oldEnd:
                oldIntervals && oldIntervals[index]
                  ? oldIntervals[index].end
                  : null,
              newStart: interval.start,
              newEnd: interval.end,
            })
          })

          const newLogObject = {
            type: extended ? 'Ext Hrs' : 'Hours',
            hoo_fieldName: 'interval',
            hoo_day: day[0],
            openIntervals: openIntervals,
          }

          savedUpdates.push(newLogObject)
        }
      })
    }

    const prepAddlText = async (text, extended = false) => {
      const getOldText = () => {
        return extended
          ? yextResponse.c_extendedAdditionalHoursText
          : yextResponse.additionalHoursText
      }

      const oldText = getOldText()

      if (oldText !== text) {
        if (!updateType.includes(extended ? 'Ext Text' : 'Hours Text')) {
          updateType += extended ? 'Ext Text, ' : 'Hours Text, '
        }

        savedUpdates.push({
          type: extended ? 'Ext Hrs' : 'Hours',
          hoo_fieldName: 'text',
          oldValue: oldText,
          newValue: text,
        })
      }
    }

    const savedUpdates = []
    let updateType = ''

    const holidayHoursObject = {}
    intialSpecialHours.filter(initDate =>
        !specialHours.current.some(changedDate =>
          initDate.date == changedDate.date)).map(async each=>{
        console.log(each)
      await deleteHolidayHours(each)
    })
    specialHours.current.map((entry) => {
      holidayHoursObject[entry.date] = entry
    })

    // MAIN HOURS
    await prepHours({
      ...standardHours.current,
      ...holidayHoursObject,
    })

    // MAIN HOURS TEXT
    prepAddlText(standardHoursText.current)

    // TOGGLE EXTENDED HOURS
    const getOldToggleExtHours = () => {
      return yextResponse.c_extendedHours &&
        Object.keys(yextResponse.c_extendedHours).length > 0
        ? true
        : false
    }

    const oldToggleExtHours = getOldToggleExtHours()

    if (oldToggleExtHours !== !!extendedHours.current) {
      if (!updateType.includes('Toggle Ext Hrs')) {
        updateType += 'Toggle Ext Hrs, '
      }

      savedUpdates.push({
        type: 'Ext Hrs',
        hoo_fieldName: 'toggle',
        oldValue: oldToggleExtHours.toString(),
        newValue: (!!extendedHours.current).toString(),
      })
    }

    if (extendedHours.current) {
      // EXTENDED HOURS
      await prepHours(extendedHours.current, true)

      // EXTENDED HOURS ADDTL TEXT
      prepAddlText(extendedHoursText.current, true)
    }

    try {
      if (updateType !== '') {
        const input = {
          userId: routingInfo.user?.id,
          userEmail: routingInfo.account.userName,
          websiteId: selectedSite.siteId.toString(),
          yextId: currentYextId.toString(),
          websiteName: selectedSite.name,
          updateType: "Hours of Operation",
          savedUpdates: savedUpdates,
          businessLine: selectedSiteInfo.websiteType,
          divisionId: yextResponse.meta?.folderId,
        }

        await addOrUpdateHOONewValueLogRest(input)
        //return

        ReactGA.event({
          category: 'Content - Hours',
          action: `User ${routingInfo.user?.id} saved change to Hours`,
          // label: `Value: ${modifiedHoursAndAdditionalText.trim()}`,
        })

        //setIsFormDirty(false)
        setIsHoursUpdated(true)

        const newHours = {
          hours: { ...standardHours.current },
          additionalHoursText: standardHoursText.current,
          c_extendedHours:
            extendedHours.current && showExtendedHours
              ? { ...extendedHours.current }
              : null,
          c_extendedAdditionalHoursText: showExtendedHours
            ? extendedHoursText.current
            : '',
          holidayHours: [...specialHours.current],
        }
        let currentUpdatedhours = JSON.parse(JSON.stringify(updatedHours))

        currentUpdatedhours[currentYextId]
          ? (currentUpdatedhours[currentYextId] = newHours)
          : (currentUpdatedhours = Object.assign(updatedHours, {
              [currentYextId]: newHours,
            }))

        setUpdatedHours(currentUpdatedhours)
      }
      setIsHoursDeleted(false)
      setIsAlertActive(true)
      setIsFormDirty(false)
      setSaving(false)
      //setIsHoursUpdated(true)
    } catch (error) {
      console.error(error)
    }
  }

  const getPreviewURL = (url) => {
    if (!url.includes('#')) {
      return `${url}#hours-panel-map`
    }
    return url
  }

  const renderStep = () => {
    switch (step) {
      case 'multilocation':
        if (multiLocations.length > 1) {
          return (
            <MultiLocationSelection
              title={'Hours of Operation'}
              description={`Choose which location you’d like to update hours for.`}
              accordionFor="multiLocationHours"
              onClick={() => {
                setStep('main')
              }}
            />
          )
        } else {
          setStep('main')
          return
        }
      case 'main':
      default:
        return (
          <div className="services-form">
            <div>
              <div id="group-title" style={{ marginBottom: '20px' }}>
                <h2 className="sidebar-header">Hours of Operation</h2>
                <p className="sidebar-para">
                  {`Change your location’s hours here. These changes appear on your website and are also published across the web.`}
                </p>
              </div>

              <div
                id="switch-container"
                className="mb-9"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                }}
              >
                <CustomSwitch
                  checked={showExtendedHours}
                  onClick={() => {
                    if (showExtendedHours) {
                      extendedHours.current = null
                    } else {
                      extendedHours.current =
                        initialHours.c_extendedHours &&
                        Object.keys(initialHours.c_extendedHours).length > 0
                          ? initialHours.c_extendedHours
                          : initHours(false)
                    }
                    setIsFormDirty(true)
                    setShowExtendedHours(!showExtendedHours)
                    updatePreview()
                  }}
                />
                <h6 className="text-lg font-semibold ml-1">
                  Extended Hours On/Off
                </h6>
              </div>

              <SectionLink>
                <>
                  <div
                    className="flex flex-col p-3 justify-between cursor-pointer"
                    onClick={() => setStep('regularHours')}
                  >
                    <div className="flex justify-between">
                      <h3 className="font-medium">Main Hours</h3>
                      <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                    </div>
                    <ParagraphSmall className="my-0">
                      Edit your primary hours of operation.
                    </ParagraphSmall>
                  </div>
                </>
              </SectionLink>

              {showExtendedHours && (
                <SectionLink>
                  <>
                    <div
                      className="flex flex-col p-3 justify-between cursor-pointer"
                      onClick={() => setStep('extendedHours')}
                    >
                      <div className="flex justify-between">
                        <h3 className="font-medium">Extended Hours</h3>
                        <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                      </div>
                      <ParagraphSmall className="my-0">
                        If your location has extended hours, you can add that information here.
                      </ParagraphSmall>
                    </div>
                  </>
                </SectionLink>
              )}

              <SectionLink>
                <>
                  <div
                    className="flex flex-col p-3 justify-between cursor-pointer"
                    onClick={() => setStep('specialHours')}
                  >
                    <div className="flex justify-between">
                      <h3 className="font-medium">Special/Holiday Hours</h3>
                      <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                    </div>
                    <ParagraphSmall className="my-0">
                      Select specific days to set different hours of operation.
                    </ParagraphSmall>
                  </div>
                </>
              </SectionLink>
            </div>
          </div>
        )

      case 'regularHours':
        return (
          <StandardHours
            hoursData={standardHours}
            text={standardHoursText}
            setIsFormDirty={setIsFormDirty}
            updatePreview={updatePreview}
            resetForm={resetForm}
            setResetForm={setResetForm}
            name="mainHours"
          />
        )
      case 'extendedHours':
        return (
          <StandardHours
            hoursData={extendedHours}
            text={extendedHoursText}
            setIsFormDirty={setIsFormDirty}
            updatePreview={updatePreview}
            resetForm={resetForm}
            setResetForm={setResetForm}
            name="extendedHours"
          />
        )
      case 'specialHours':
        return (
          <SpecialHours
            hoursData={specialHours}
            setIsFormDirty={setIsFormDirty}
            deleteHolidayHours={deleteHolidayHours}
            updatePreview={updatePreview}
            resetForm={resetForm}
            setResetForm={setResetForm}
            errorRef={errorRef}
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            name="specialHours"
          />
        )
    }
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty && !isEBDeleted}
        message={() => hoursMessages.CONFIRM_LOSE_UNSAVED_HOURS}
      />
      <div
        className="h-full py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
        }}
      >
        <DesktopMobileToggle
          setIframeView={setIframeView}
          iframeView={iframeView}
          sendOnLoadMsg={sendOnLoadMsgOnToggle}
        />{' '}
        <PreviewFrame
          siteName={selectedSite.name}
          selectedSite={getPreviewURL(
            location?.multiLocationHours?.siteURL || selectedSite.siteURL
          )}
          // selectedSite="http://localhost:8000"
          sendOnLoadMsg={sendOnLoadMsg}
          iframeView={iframeView}
        />
      </div>
      <Sidebar
        width={425}
        height={'100vh'}
        isClosed={(val) => setIsSidebarClosed(val)}
        saveChanges={saveChanges}
        saving={saving}
        saveButtonDisabled={!isFormDirty}
        onBackClick={() => {
          goBack()
        }}
        userGroups={user.groups}
        showResetButton
        resetChanges={resetChanges}
        showErrorOutline={showErrorMessage}
      >
        {renderStep()}
      </Sidebar>
    </div>
  )
}

export default WebsitePageHours
